/* eslint-disable max-lines-per-function, max-lines */
import { on as onEvent } from "common/utils/ui/event";
import { track, initMixpanelEvents } from "jiffy-analytics";
import { api } from "jiffy-api";
import { waitForWindowVariable } from "common/utils/ui/wait_for";

const $getOrderNumberContainer = () => document.querySelector(".js-order-number-container");

const $getOrderFeatureUsagesContainer = () =>
  document.querySelector(".js-order-feature-usages-container");

const getOrderNumber = () => $getOrderNumberContainer()?.dataset?.orderNumber;

const updateFeatureUsage = feature => {
  const orderNumber = getOrderNumber();
  if (!orderNumber) return;

  const allowedFeatures = JSON.parse(
    $getOrderFeatureUsagesContainer()?.dataset?.allowedFeatures || "[]"
  );
  if (!allowedFeatures.includes(feature)) return;

  const updateAllowedFeatures = param => {
    // The following code is for when the feature usage is getting updated without
    // navigating to other page, i.e. because of Ctrl + clicking on a link or choosing
    // "Open in new tab/window" on a link context menu.
    const $orderFeatureUsageContainer = $getOrderFeatureUsagesContainer();
    if (!$orderFeatureUsageContainer) return;

    let allowed;
    if (param) {
      const { allowed_features: updatableFeatures } = param;
      allowed = updatableFeatures;
    } else {
      allowed = allowedFeatures.filter(f => f !== feature);
    }

    $orderFeatureUsageContainer.dataset.allowedFeatures = JSON.stringify(allowed);
  };
  const notifyHoneybadger = error => {
    if (window.Honeybadger) {
      window.Honeybadger.notify("Order Feature Usage Updation failed", {
        params: {
          orderNumber,
          feature,
          error: {
            message: error.message,
            stack: error.stack,
            name: error.name
          }
        }
      });
    }
  };

  waitForWindowVariable("JiffyApi", () => {
    api.featureUsage
      .update({ order_number: orderNumber }, { feature })
      .then(updateAllowedFeatures)
      .catch(notifyHoneybadger);
  });
};

const initTopMenuEvents = () => {
  const links = document.querySelectorAll(".masthead-menu a");
  links.forEach(link => {
    link.addEventListener("click", () => {
      const $title =
        link.closest(".js-masthead-s-nav-col")?.querySelector(".js-masthead-s-nav-col-title") ||
        link.closest(".js-masthead-menu-sub")?.querySelector(".js-masthead-menu-item-text");
      track("nav_clicked", {
        nav_location: "top",
        filter_name: link.textContent,
        parent: ($title && $title.textContent.trim()) || "",
        version: "v1"
      });

      updateFeatureUsage("top_nav");
    });
  });
};

const initSideMenuEvents = () => {
  const callback = ({ target: $target }) => {
    const $title =
      $target.parentElement.querySelector(".js-aside-title") ||
      $target.closest(".softness-filter")?.parentElement?.querySelector(".js-aside-title") ||
      $target
        .closest(".js-aside-item")
        .parentElement.querySelector(".js-aside-title")
        .querySelector(".js-jiffy-first-badge");

    const filterName =
      $target &&
      ($target.parentElement.dataset.gaEventLabel ||
        $target.textContent.trim() ||
        $target.parentElement.textContent.trim());

    if (filterName) {
      track("nav_clicked", {
        nav_location: "side",
        filter_name: filterName,
        parent: ($title && $title.textContent.trim()) || "",
        version: "v1"
      });

      updateFeatureUsage("side_nav");
    }
  };

  const dropdownCallback = () => {
    track("primary_material_filter_dropdown_opened", {});
  };

  const dropdownSelectCallback = ({ target: $target }) => {
    track("primary_material_filter_dropdown_selected", {
      material: $target.options[$target.selectedIndex].value
    });
  };

  onEvent("click", ".products__categories a", callback, true);
  onEvent("click", ".products__categories select", dropdownCallback, true);
  onEvent("change", ".products__categories select", dropdownSelectCallback, true);
};

const initProductClickEvents = () => {
  const callback = ({ target: $target }) => {
    const $productItem = $target.closest(".js-products__item");
    const $lineItem = $target.closest(".js-item-details-tracker");
    const { gaList, gaPosition } = $productItem?.dataset ?? {};
    const {
      name,
      brand,
      category,
      compositionId,
      composition,
      pageName,
      fulfillmentGroup,
      clickContext,
      itemPosition,
      businessLine
    } = $lineItem.dataset;

    const productCardHeading = clickContext || gaList;
    const dbFeatureTracking = ["similar_products", "matching_products"];
    window.localStorage.setItem("pdpReferrerSection", productCardHeading);

    track("product_clicked", {
      version: "2",
      product: name,
      brand,
      product_category: category,
      spree_composition_id: compositionId,
      composition,
      fulfillment_group: fulfillmentGroup,
      page_name: pageName,
      click_context: productCardHeading || pageName,
      item_position: itemPosition || gaPosition,
      business_line: businessLine
    });

    const argument = productCardHeading?.toLowerCase()?.replace(" ", "_");
    if (dbFeatureTracking.includes(argument)) {
      updateFeatureUsage(argument);
    }
  };

  onEvent("click", ".js-product-item-trigger", callback, true);
};

const initCheckoutConfirmedEvents = () => {
  $(document).on("click", ".checkout-confirm-button__action, #purchase_button", button => {
    const orderTotal = document
      .querySelector("#checkout-order-total")
      .innerText.replace(/[^0-9.-]+/g, "");
    let paymentSource = "";

    if (button.currentTarget.className === "checkout-confirm-button__action") {
      paymentSource = "paypal";
    } else if (button.currentTarget.id === "purchase_button") {
      paymentSource = "default";
    }

    track("checkout_confirmed", {
      amount: Number(orderTotal),
      source_of_payment: paymentSource
    });
  });
};

const initFavoritesPageEvents = () => {
  if (window.location.href.includes("account/favorite_items")) {
    track("favorites_page_loaded");
  }
};

const initCustomerEvents = () => {
  const element = document.querySelector(".alert-success, .flash-message__text--flash-success");
  if (element) {
    const welcomeMsg = element.innerHTML;
    const $oauthProviderMetaTag = document.head.querySelector('meta[property="oauthProviderUsed"]');
    const loginType = $oauthProviderMetaTag ? $oauthProviderMetaTag.content : "Email";
    const loginEventVersion = "2";
    const connectEventVersion = "0";

    if (welcomeMsg.includes("Welcome back")) {
      track("customer_logged_in", { type: loginType, version: loginEventVersion });
    } else if (welcomeMsg.endsWith("is disconnected")) {
      track("social_account_disconnected", { type: loginType, version: connectEventVersion });
    } else if (welcomeMsg.endsWith("is connected")) {
      track("social_account_connected", { type: loginType, version: connectEventVersion });
    }
  }
};

const initResetMixpanelWhenLogout = () => {
  $(document).on("click", ".user-logout-btn", () => {
    if (window.mixpanel) {
      window.mixpanel.reset();
    }
  });
};

const initCollapsibleSearchEvents = () => {
  const $productFormCheckbox = document.querySelector(".js-aside-filter-checkbox");
  $productFormCheckbox?.addEventListener("change", event => {
    const $form = document.querySelector(".js-aside-filter-form");
    track("filter_product_only_in_stock_clicked", {
      toggle: event.target.checked,
      href: $form?.action
    });
  });
};

const quickStyleFinderEvents = () => {
  const $qsfTitle = document.querySelector(".js-quick-style-finder-title");
  $qsfTitle?.addEventListener("click", () => {
    const $qsfDetails = document.querySelector(".js-quick-style-finder-details");
    track("quick_style_finder_toggled", {
      toggle: $qsfDetails?.getAttribute("open") == null
    });
  });

  const $qsfForm = document.querySelector(".js-products-extended-filter-form");
  $qsfForm?.addEventListener("submit", () => {
    track("quick_style_finder_form_submitted", Object.fromEntries(new FormData($qsfForm)));
  });
};

const initPlpPaginationEvents = () => {
  const $links = Array.from(document.querySelectorAll(".js-plp-search-pagination a"));
  $links.forEach($link => {
    $link.addEventListener("click", () => {
      track("plp_pagination_link_clicked", {
        href: $link.href,
        page: $link.textContent
      });
    });
  });
};

const initSideNavToggle = () => {
  const $links = document.querySelectorAll(".js-aside-filter-toggle");
  $links.forEach($link => {
    const $title = $link.parentElement.querySelector(".js-aside-title");

    $link.addEventListener("click", () => {
      track("side_nav_accordian_clicked", {
        action: $link.dataset.collapsed === "true" ? "see_all" : "see_less",
        parent: ($title && $title.textContent.trim()) || ""
      });
    });
  });
};

const initTrackProductTilesShip = () => {
  const callback = ({ target: $target }) => {
    const {
      brand,
      category,
      compositionId,
      composition,
      pageName,
      fulfillmentGroup,
      businessLine
    } = $target.dataset;
    const $productItem = $target.closest(".js-products__item");
    const { gaPosition, gaList } = $productItem?.dataset || {};

    track("fulfillment_plp_filter_clicked", {
      brand,
      product_category: category,
      spree_composition_id: compositionId,
      composition,
      fulfillment_group: fulfillmentGroup,
      page_name: pageName,
      click_context: gaList,
      item_position: gaPosition,
      business_line: businessLine
    });
  };

  onEvent("click", ".js-ships-tooltip", callback, true);
};

const trackProductGridScroll = (elementClass, direction) => {
  const callback = ({ target: $target }) => {
    const { mpListHeader: name } = $target.dataset;
    track("subsection_carousel_clicked", {
      direction,
      name
    });
  };

  onEvent("click", elementClass, callback, true);
};

const initTrackProductGridScroll = () => {
  trackProductGridScroll(".js-product-grid-scroll-left", "left");
  trackProductGridScroll(".js-product-grid-scroll-right", "right");
};

const initTrackUseThisAddressBtn = () => {
  const callback = () => {
    track("use_this_address_button_clicked");
  };

  onEvent("click", ".js-checkout-guest-address-button", callback, true);
};

const initTrackCheckoutUseShippingAddress = () => {
  const callback = ({ target: $target }) => {
    track("same_as_shipping_address_clicked", {
      action: $target.checked ? "checked" : "unchecked"
    });
  };

  onEvent("change", ".js-order-use-shipping-checkbox", callback, true);
};

const init = () => {
  initMixpanelEvents();
  initTopMenuEvents();
  initSideMenuEvents();
  initProductClickEvents();
  initCheckoutConfirmedEvents();
  initFavoritesPageEvents();
  initCustomerEvents();
  initResetMixpanelWhenLogout();
  initCollapsibleSearchEvents();
  quickStyleFinderEvents();
  initPlpPaginationEvents();
  initSideNavToggle();
  initTrackProductTilesShip();
  initTrackProductGridScroll();
  initTrackUseThisAddressBtn();
  initTrackCheckoutUseShippingAddress();
};

document.addEventListener("DOMContentLoaded", init);
